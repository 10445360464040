<template>
	<div class="wrap">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="关键字">
					<el-input v-model="searchForm.keyword" placeholder="姓名/手机号码" @submit.native.prevent></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="loadData(1)">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">添加记录</el-button>
					<el-button type="primary" @click="exportCustomer">导出</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="created_at" label="日期" width="100">
				<template slot-scope="scope">
					{{scope.row.created_at && scope.row.created_at.substring(0,10)}}
				</template>
			</el-table-column>
			<el-table-column prop="series" label="系列" width="200"></el-table-column>
			<el-table-column prop="no" label="编号" width="200"></el-table-column>
			<el-table-column prop="name" label="名称" width="100"></el-table-column>
			<el-table-column prop="capacity" label="容量" width="200"></el-table-column>
			<el-table-column prop="num" label="数量" width="200"></el-table-column>
			<el-table-column prop="autograph" label="签名" width="200"></el-table-column>
			<el-table-column prop="remarks" label="备注"></el-table-column>
			<el-table-column fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button size="mini" @click="$router.push(`/tables/receive/edit/${scope.row.id}`)">编辑</el-button>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: [],
				searchForm: {
					keyword: '',
					page: 1,
					page_size: 10
				},
				total: 0
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(val) {
				if (val) {
					this.$router.push(`/tables/receive/edit/${val.id}`)
				} else {
					this.$router.push(`/tables/receive/edit`)
				}
			},
			loadData() {
				this.loading = true;
				this.$api.table.getCollectList(this.searchForm).then().then(res => {
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.total;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
			del(item) {
				const loading = this.$loading()
				this.$api.table.collectDel({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message.success(`操作成功`);
						this.loadData();
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			},
			//导出数据
			exportCustomer(){
				this.$api.table.collectExport(this.searchForm).then(res => {
					let a = document.createElement('a');
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					});
					let objectUrl = URL.createObjectURL(blob);
					a.setAttribute("href", objectUrl);
					a.setAttribute("download", '产品领用导出.xls');
					a.click();
				})
			},
		}
	};
</script>
<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-right: 5px;
	}
</style>
